import React from "react";
import {Col, Row} from "react-bootstrap";
import {
  DiNodejs,
  DiMongodb,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiSolidity,
  SiPostgresql, SiRust, SiPython, SiPhp, SiNginx, SiLinux, SiFastapi, SiSpring, SiReact,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
      <Col xs={4} md={2} className="tech-icons">
        <SiSolidity/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRust/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPhp/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNginx/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiReact/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFastapi/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpring/>
      </Col><Col xs={4} md={2} className="tech-icons">
      <DiNodejs/>
    </Col>
    </Row>
  );
}

export default Techstack;
