import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import monitor from "../../Assets/Projects/monitor-cli.png";
import yamcha from "../../Assets/Projects/yamcha.webp";
import yamcha2 from "../../Assets/Projects/yamcha2.webp";
import chat from "../../Assets/Projects/chat.webp";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle/>
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{color: "white"}}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{justifyContent: "center", paddingBottom: "10px"}}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yamcha}
              isBlog={false}
              title="Yamcha - Telegram Bot"
              description="My personal bot on telegram build with Rust and Teloxide. Supports complex commands and easy to use. Make things easier for you."
              ghLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chat}
              isBlog={false}
              title="Chat.rs - Chat App"
              description="API for a simple chat application. Build with Rust, Actix-Web, and Diesel. Supports user registration, login, and chat."
              ghLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yamcha2}
              isBlog={false}
              title="Yamcha - Discord Bot"
              description="Online Discord Bot build with Rust and Serenity. Supports complex commands and easy to use. Make things easier for you and your server members. Flow commands, music, moderation, and more."
              ghLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={monitor}
              isBlog={false}
              title="Monitor CLI"
              description="A simple CLI tool to monitor your application and get notified when it's down, in your platforms: discord, telegram, and slack. Build with Rust, clap and reqwest."
              ghLink=""
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
