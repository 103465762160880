import React from "react";
import Card from "react-bootstrap/Card";
import {ImPointRight} from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{textAlign: "justify"}}>
            Hi Everyone, I am <span className="purple">Fritz Henrique </span>
            from <span className="purple"> Roraima, Brasil.</span>
            <br/>
            I am currently employed as a software developer at Pigz.
            <br/>
            Currently studying software engineering at Descomplica.
            <br/>
            <br/>
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight/> Playing Basketball
            </li>
            <li className="about-activity">
              <ImPointRight/> Writing Rust Code
            </li>
            <li className="about-activity">
              <ImPointRight/> Reading Books
            </li>
          </ul>

          <p style={{color: "rgb(155 126 172)"}}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
