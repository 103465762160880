import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {
  AiFillGithub, AiFillLinkedin, AiOutlineWhatsApp,
} from "react-icons/ai";
import {FaTelegramPlane} from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>"I just seek to be better every day..."</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} DevFritz</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/dev-fritz"
                style={{color: "white"}}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub/>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://wa.me/5595991561987"
                style={{color: "white"}}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineWhatsApp/>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/fritzhenrique/"
                style={{color: "white"}}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillLinkedin/>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://t.me/fritzhenrique"
                style={{color: "white"}}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegramPlane/>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
